import { FiscalQuarter } from "../types";
import {
  FiscalYear,
  URLString,
  URLStringOrNull,
  WorkerStatus,
} from "../types";

export const ConferenceEventCategory = {
  EARNINGS_CALL: "EARNINGS_CALL",
};

export type ConferenceEventCategory =
  (typeof ConferenceEventCategory)[keyof typeof ConferenceEventCategory];

export type ConferenceEventProps<CompanyType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  company: CompanyType;
  eventName: string;
  category: ConferenceEventCategory;
  fiscalQuarter: FiscalQuarter;
  fiscalYear: FiscalYear;
  eventDate: Date;
  callStartsAt: Date;
  webcastUrl?: URLStringOrNull;
  lastAudioFileProcessedAt?: Date | null;
  isRecordingInProgress?: boolean;
  dialInPhoneNumber?: string | null;
  dialInInstructions?: string | null;
  webcastRecordingUrl?: string | null;
  callDurationInSeconds?: number | null;
  workerStatus: WorkerStatus;
  dynoId?: string | null;
  lastDynoHeartbeatAt?: Date | null;
};

export default class BaseConferenceEvent<CompanyType, EarningsEventType> {
  id!: number;

  createdAt?: Date;

  updatedAt?: Date;

  company!: CompanyType;

  eventName!: string;

  category!: ConferenceEventCategory;

  fiscalQuarter!: FiscalQuarter;

  fiscalYear!: FiscalYear;

  eventDate!: Date;

  callStartsAt!: Date;

  webcastUrl?: URLString | null;

  isRecordingInProgress?: boolean;

  lastAudioFileProcessedAt?: Date | null;

  dialInPhoneNumber?: string | null;

  dialInInstructions?: string | null;

  earningsEvent?: EarningsEventType;

  webcastRecordingUrl?: URLString | null;

  callDurationInSeconds?: number | null;
  
  workerStatus!: WorkerStatus;
  
  dynoId?: string | null;
  
  lastDynoHeartbeatAt?: Date | null;
  
  constructor(props: ConferenceEventProps<CompanyType>) {
    Object.assign(this, props);
  }

  toString() {
    return `${this.eventName} [${this.fiscalQuarter} ${this.fiscalYear}] on ${this.callStartsAt}, hosted @ ${this.webcastUrl}`;
  }
  
}