import { FC, useEffect, useState } from "react";
import { Typography, Grid, Container, Paper, Divider, Box, Snackbar, Alert, CircularProgress } from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useUserContext } from "../../contexts/UserContext";
import FormField from "../../components/FormField";
import ErrorToast from "../../components/ErrorToast";
import SecondaryButton from "../../components/SecondaryButton";

type FormValues = {
  webhookUrl: string;
};

const Profile: FC = () => {
  const { authenticatedUserProfile, setAuthenticatedUserProfile } = useUserContext();
  const [generalError, setGeneralError] = useState("");
  const [initialValues, setInitialValues] = useState<FormValues>({ webhookUrl: authenticatedUserProfile?.configurations?.webhookConfig?.webhookUrl || "https://" });
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setInitialValues({ webhookUrl: authenticatedUserProfile?.configurations?.webhookConfig?.webhookUrl || "https://" });
  }, [authenticatedUserProfile]);
  
  const validationSchema = Yup.object({
    webhookUrl: Yup.string()
      .url("The URL you've entered is invalid.")
      .required("A Webhook URL is required."),
  });
  
  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setGeneralError("");
    setMessage(null);
    setLoading(true);
    if (!authenticatedUserProfile?.user) {
      setGeneralError("You are not logged in.");
      setLoading(false);
      return;
    }
    if (authenticatedUserProfile?.configurations?.webhookConfig?.webhookUrl === values.webhookUrl) {
      setGeneralError("The URL you've entered is the same as the current configuration.");
      setLoading(false);
      return;
    }
    const { data, error } = await authenticatedUserProfile.updateWebhookConfig(values.webhookUrl);
    setLoading(false);
    setSubmitting(false);
    if (error || !data) {
      setGeneralError(error);
      return;
    }
    authenticatedUserProfile.configurations.webhookConfig = data;
    setAuthenticatedUserProfile(authenticatedUserProfile);
    setMessage("Webhook URL updated successfully.");  };

  return (
    <>
      <Snackbar
        open={!!message}
        autoHideDuration={7500}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ top: "74px !important" }}
      >
        <Alert onClose={() => setMessage(null)} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <ErrorToast dismiss={() => setGeneralError("")} message={generalError} />
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, mt: 5 }}>
          <Typography variant="h5" gutterBottom>
            Profile
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="textSecondary">Email</Typography>
              <Typography variant="body1">{authenticatedUserProfile?.user.email}</Typography>
              <Typography variant="subtitle1" color="textSecondary" mt={2}>Name</Typography>
              <Typography variant="body1">{authenticatedUserProfile?.user.name}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" gutterBottom mt={3}>
            Webhook Configuration
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Box display="flex" alignItems="start">
                      <Box flexGrow={1} mr={2}>
                        <FormField name="webhookUrl" />
                      </Box>
                      {loading ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress/>
                      </Box>
                    ): (
                    <SecondaryButton
                      label="Update"
                      type="submit"
                      disabled={isSubmitting}
                    />)}
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Profile;