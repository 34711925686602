import { URLString } from "../types";

export type WebhookConfigProps<UserType> = {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  owner: UserType;
  webhookUrl: URLString;
};

export default class BaseWebhookConfig<UserType> {
  id!: string;

  createdAt?: Date;

  updatedAt?: Date;

  owner!: UserType;

  webhookUrl!: URLString;

  constructor(props: WebhookConfigProps<UserType>) {
    Object.assign(this, props);
  }
}