import { DateTime } from "luxon";

export const FiscalQuarter = {
  Q1: "Q1",
  Q2: "Q2",
  Q3: "Q3",
  Q4: "Q4",
} as const;

export type FiscalQuarter =
  (typeof FiscalQuarter)[keyof typeof FiscalQuarter];

export type FiscalYear = number;

export const ReportingWindow = {
  AFTER_HOURS: "AFTER_HOURS",
  BEFORE_OPEN: "BEFORE_OPEN",
  DURING_MARKET_HOURS: "DURING_MARKET_HOURS",
} as const;

export type ReportingWindow =
  (typeof ReportingWindow)[keyof typeof ReportingWindow];
  
export const Country = {
  UNITED_STATES: "UNITED_STATES",
} as const;

export type Country =
  (typeof Country)[keyof typeof Country];

export type URLString = `http${"" | "s"}://${string}`;

export type URLStringOrNull = URLString | null;

export type StringOrNull = string | null;

export type DateOrNull = Date | null;

export const WorkerStatus = {
  UPCOMING: "UPCOMING",
  WAITING: "WAITING",
  AUTHENTICATED: "AUTHENTICATED",
  LIVE: "LIVE",
  RESTARTING: "RESTARTING",
  ENDED: "ENDED",
} as const;

export type WorkerStatus = (typeof WorkerStatus)[keyof typeof WorkerStatus];

export const isUrlString = (
  url: string | null | undefined,
): url is URLString => {
  return (
    typeof url === "string" &&
    (url.startsWith("http://") || url.startsWith("https://"))
  );
};

type Year = `${number}`;
type Month = `0${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}` | `1${0 | 1 | 2}`;
type Day =
  | `0${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`
  | `${1 | 2}${number}`
  | `3${0 | 1}`;
type Hour = `0${number}` | `1${number}` | `2${0 | 1 | 2 | 3}`;
type Minute = `0${number}` | `${1 | 2 | 3 | 4 | 5}${number}`;
type Second = Minute;

export type ISODateString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}.000Z`;

type DayOfMonth = `${0 | 1 | 2 | 3}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`;

type MonthOfYear =
  | "01"
  | "02"
  | "03"
  | "04"
  | "05"
  | "06"
  | "07"
  | "08"
  | "09"
  | "10"
  | "11"
  | "12";

export type YYYYMMDDFormat = `${number}-${MonthOfYear}-${DayOfMonth}` | "";

export type FunctionName<F> = F extends (...args: unknown[]) => unknown
  ? (F & { name: string })["name"]
  : never;

export type ConferenceEventDetails = {
  eventName: string;
  fiscalQuarter: FiscalQuarter;
  fiscalYear: FiscalYear;
  callStartsAt: DateTime;
  webcastUrl: URLString;
};

	