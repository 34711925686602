import { Button } from "@mui/material";
import styles from "./SecondaryButton.module.scss";

type SecondaryButtonProps = {
  label: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
};

const SecondaryButton = ({ label, type = "button", disabled = false }: SecondaryButtonProps) => (
  <Button
    type={type}
    disabled={disabled}
    variant="text"
    color="primary"
    className={styles.secondaryButton}
    sx={{ textTransform: "none" }}
  >
    {label}
  </Button>
);

export default SecondaryButton;