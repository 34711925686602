import { FC, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Link } from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import { UserProfile } from "../../models";
import { useUserContext } from "../../contexts/UserContext";
import FormField from "../../components/FormField";
import PrimaryButton from "../../components/PrimaryButton";
import LeftSection from "./LeftSection";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import styles from "./CreateAccount.module.scss"; // Import the SCSS module

type FormValues = {
  name: string;
  email: string;
  password: string;
}

const CreateAccount: FC = () => {
  const navigate = useNavigate();
  const [generalError, setGeneralError] = useState("");
  const { logInUser } = useUserContext();

  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Full name is required."),
    email: Yup.string()
      .matches(
        /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid email address"
      )
      .required("Email is required."),
    password: Yup.string()
      .min(10, "Password must be at least 10 characters.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
      .matches(/[0-9]/, "Password must contain at least one number.")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character."
      )
      .required("Password is required."),
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setGeneralError(""); // Reset general error
    const { userProfile, token, error } = await UserProfile.createUser(values.name, values.email, values.password);
    setSubmitting(false);
    if (error || !userProfile || !token) {
      setGeneralError(error as string);
      return;
    }
    logInUser(userProfile);
    localStorage.setItem("earningsStreamToken", token);
    navigate("/profile");
  };

  return (
    <ResponsiveContainer>
      <Grid container className={styles.container}>
        <LeftSection />
        <div className={styles.rightSectionLogo}>
          <Typography
            variant="h4"
            gutterBottom
            mt={3}
            mb={3}
            pl={0}
            sx={{ fontSize: "1.5rem" }}
          >
            <span className={styles.companyName}>EarningsStream</span>
          </Typography>
        </div>
        <Grid item xs={12} md={6} className={styles.rightSection}>
          <Box className={styles.formBox}>
            <Typography
              gutterBottom
              sx={{ fontSize: "1.5rem", fontWeight: 600 }}
              mb={3}
            >
              Create your account
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormField name="name" label="Full name" />
                  <FormField name="email" label="Email" />
                  <FormField name="password" label="Password" type="password" />
                  <PrimaryButton
                    label="Create account"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  {generalError && (
                    <Typography
                      color="error"
                      variant="body2"
                      align="center"
                      mt={2}
                    >
                      {generalError}
                    </Typography>
                  )}
                </Form>
              )}
            </Formik>
            <Typography
              variant="body2"
              align="center"
              className={styles.signInText}
              mt={4}
            >
              Already have an account?{" "}
              <Link component={RouterLink} to="/login" className={styles.boldLink}>
                Sign in
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

export default CreateAccount;