export type RoleProps<CompanyType, PersonType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  title: string;
  startDate?: Date | null;
  endDate?: Date | null;
  company: CompanyType;
  person: PersonType;
};

export default class BaseRole<CompanyType, PersonType> {
  id!: number;

  createdAt?: Date;

  updatedAt?: Date;

  title!: string;

  startDate?: Date;

  endDate?: Date;

  company!: CompanyType;

  person!: PersonType;

  static OPERATOR = "operator";

  static ROLE_CLEANING_MAP = {
    mister: "Mr.",
    ceo: "CEO",
    "chief executive officer": "Chief Executive Officer",
    cfo: "CFO",
    "chief financial officer": "Chief Financial Officer",
    secretary: "Secretary",
    "of the board": "of the Board",
    [this.OPERATOR]: "Operator",
  };

  static COMPANY_PARTICIPANT_ROLES = ["ceo", "cfo", "secretary"];

  constructor(props: RoleProps<CompanyType, PersonType>) {
    const id = props.id || -1;
    Object.assign(this, { ...props, id });
  }

  toString() {
    return `${this.title} at ${this.company}`;
  }
}
