import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LogoButton = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 5 }}
      onClick={() => navigate("/")}
    >
      <Typography
        variant="h6"
        sx={{ fontSize: "18px", fontWeight: "bold", color: "#fff", textTransform: "none" }}
      >
        EarningsStream
      </Typography>
    </Box>
  );
};

export default LogoButton;