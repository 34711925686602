import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.contents}>
        <Typography variant="body2" align="center" color="textSecondary" fontWeight={600}>
          © <Link component={RouterLink} to="/" color="textSecondary" sx={{ textDecoration: "none", fontWeight:600 }}>EarningsStream</Link>
          {/* &nbsp;•&nbsp; */}
          {/* <Link component={RouterLink} to="/privacy" color="textSecondary" sx={{ textDecoration: "none", fontWeight:600 }}>Privacy & terms</Link> */}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;