import React from "react";
import { Box, Button, List, ListItem, ListItemText, Typography, Divider, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ChevronRight } from "@mui/icons-material";
import { useUserContext } from "../../contexts/UserContext";

type NavDrawerProps = {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleLogoutClick: () => void;
};

const NavDrawer: React.FC<NavDrawerProps> = ({ toggleDrawer, handleLogoutClick }) => {
  const { authenticatedUserProfile } = useUserContext();

  return (
    <Box
      sx={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2, py: 1 }}>
          <Typography variant="h6" sx={{ color: "#2b6653", fontWeight: 600 }}>EarningsStream</Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {authenticatedUserProfile && (
            <>
              <ListItem component={RouterLink} to="/profile">
                <ListItemText primary="Dashboard" sx={{ color: "#2b6653" }} />
              </ListItem>
              <ListItem component={RouterLink} to="/upcoming-earnings-events">
                <ListItemText primary="Earnings Calendar" sx={{ color: "#2b6653" }} />
              </ListItem>
            </>
          )}
          <ListItem
            component={RouterLink}
            to="https://earningsstream.gitbook.io/earnings-stream"
          >
            <ListItemText primary="Developer Guide" sx={{ color: "#2b6653" }} />
          </ListItem>
        </List>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        {authenticatedUserProfile ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2b6653",
              color: "#fff",
              textTransform: "none",
              fontSize: "15px",
              fontWeight: "bold",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center"
            }}
            onClick={handleLogoutClick}
          >
            Log out
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2b6653",
              color: "#fff",
              textTransform: "none",
              fontSize: "15px",
              fontWeight: "bold",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center"
            }}
            component={RouterLink}
            to="/login"
          >
            Sign in <ChevronRight />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default NavDrawer;