import { ReactNode } from "react";
import { Grid, Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styles from "./LeftSection.module.scss";

const InfoHeader = ({ label }: { label: string }) => (
	<Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: 500 }}>
		{label}
	</Typography>
);

const InfoText = ({ text }: { text: ReactNode }) => (
	<Typography variant="body1" className={styles.infoText} sx={{ fontSize: "14px" }}>
		{text}
	</Typography>
);

const InfoBox = ({ label, text }: { label: string; text: ReactNode }) => (
	<Box className={styles.infoBox}>
		<CheckCircleIcon className={styles.icon} />
		<Box>
			<InfoHeader label={label} />
			<InfoText text={text} />
		</Box>
	</Box>
);

const LeftSection = () => {
	return (
		<Grid item xs={12} md={6} className={styles.leftSection}>
		<Typography variant="h4" gutterBottom mb={4} pl={3}>
			<span className={styles.companyName}>EarningsStream</span>
		</Typography>
		<InfoBox
			label="Get started fast"
			text="With our developer-friendly guide and sandbox environment, you'll be set up to receive real-time data in under 10 minutes."
		/>
		<InfoBox
			label="Unparalled performance"
			text="A low-latency, highly accurate, and affordable solution that scales with your needs."
		/>
		<InfoBox
			label="For the biggest funds and individual investors alike"
			text={<><span className={styles.companyName}>EarningsStream</span> supports investors and strategies of all shapes and sizes.</>}
		/>
	</Grid>
	);
};

export default LeftSection;