import User from "./User";
import WebhookConfig from "./WebhookConfig";
import FetchApi, { Response } from "../services/FetchApi";

type Configurations = {
	webhookConfig: WebhookConfig;
};

type UserProfileProps = {
  user: User;
  configurations: Configurations;
};

type AuthenticateSuccessfulResponse =  { user: User; configurations: Configurations; token: string; error: null; };

type AuthenticationUnsuccessfulResponse = { user: User; configurations: Configurations; token: null; error: string; };

type AuthenticationSuccessfulReturn = { userProfile: UserProfile; token: string; error: null; };

type AuthenticationUnsuccessfulReturn = { userProfile: null; token: null; error: string; };

type AuthenticationResponse = AuthenticateSuccessfulResponse | AuthenticationUnsuccessfulResponse;

type AuthenticationReturn = AuthenticationSuccessfulReturn | AuthenticationUnsuccessfulReturn;

type FetchMeResponse = { userProfile: UserProfile | null; error: string | null };

type StartDemoReturn = boolean;

export default class UserProfile {
	user!: User;
	configurations!: {
		webhookConfig: WebhookConfig;
	};
	
	constructor(props: UserProfileProps) {
		Object.assign(this, props);
	}

	
	static async createUser(name: string, email: string, password: string): Promise<AuthenticationReturn>{
		let error = null;
		const response = await FetchApi.post<AuthenticationResponse>({
			expectedResponse: 201,
			endpoint: "register",
			payload: { name, email, password }
		});
		if (response.error || !response.data?.user || !response.data?.configurations || !response.data?.token) {
			error = response.error || "Failed to create user. Please try again.";
			return { userProfile: null, token: null, error }
		} else {
			const token = response.data.token;
			const userProfile = UserProfile.deserialize({ user: response.data.user, configurations: response.data.configurations });
			return { userProfile, token, error: null}
		}
	}
	
	static async logIn(email: string, password: string): Promise<AuthenticationReturn> {
		let error = null;
		const response = await FetchApi.post<AuthenticationResponse>({
			expectedResponse: 200,
			endpoint: "login",
			payload: { email, password }
		});
		if (response.error || !response.data?.user || !response.data?.configurations || !response.data?.token) {
			error = response.error || "Failed to log in user. Please try again.";
			return { userProfile: null, token: null, error }
		} else {
			const token = response.data.token;
			const userProfile = UserProfile.deserialize({ user: response.data.user, configurations: response.data.configurations });
			return { userProfile, token, error: null}
		}
	}
	
	static async fetchMe(): Promise<FetchMeResponse> {
		let userProfile = null;
		let error = null;
		const response = await FetchApi.get<UserProfile>({
			expectedResponse: 200,
			endpoint: "me"
		});
		if (response.error || !response.data) {
			error = response.error || "Failed to fetch your profile.";
		} else {
			userProfile = UserProfile.deserialize(response.data)
		}
		return { userProfile, error };
	}
	
	static async fetchConferenceEvent(conferenceEventId: string): Promise<Response<any>> {
		return FetchApi.get<any>({
			expectedResponse: 200,
			endpoint: "conferenceEvents",
			params: { conferenceEventId },
		});
	}
	
	static async fetchConferenceEventLiveStreamFile(conferenceEventId: string, fileOrder: string): Promise<Response<any>> {
		return FetchApi.get<any>({
			expectedResponse: 200,
			endpoint: "conferenveEventLiveStreamFiles",
			params: { conferenceEventId, fileOrder },
		});
	}
	
	async updateWebhookConfig(webhookUrl: string): Promise<Response<WebhookConfig>> {
		return FetchApi.post<WebhookConfig>({
			expectedResponse: 201,
			endpoint: "webhookConfigs",
			payload: { webhookUrl }
		});
	};
	
	static deserialize(props: UserProfileProps): UserProfile {
		const user = User.deserialize(props.user);
		const configurations = {
			webhookConfig: WebhookConfig.deserialize(props.configurations.webhookConfig)
		};
		return new UserProfile({ user, configurations });
	}
}