import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import { UserProfile } from "../../models";
import { useUserContext } from "../../contexts/UserContext";
import FormField from "../../components/FormField";
import PrimaryButton from "../../components/PrimaryButton";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import ErrorToast from "../../components/ErrorToast";
import styles from "./SignIn.module.scss"; // Import the SCSS module

type FormValues = {
  email: string;
  password: string;
};

const SignIn: FC = () => {
  const navigate = useNavigate();
  const [generalError, setGeneralError] = useState("");
  const { logInUser } = useUserContext();
  
  const initialValues = {
    email: "",
    password: "",
  };
  
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required."),
    password: Yup.string()
      .required("Password is required."),
  });
  
  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setGeneralError(""); // Reset general error
    const { userProfile, token, error } = await UserProfile.logIn(values.email, values.password);
    setSubmitting(false);
    if (error || !userProfile || !token) {
      setGeneralError(error as string);
      return;
    }
    logInUser(userProfile);
    localStorage.setItem("earningsStreamToken", token);
    navigate("/profile");
  };
  
  return (
    <ResponsiveContainer>
      <ErrorToast dismiss={() => setGeneralError("")} message={generalError} pixelsFromTop={16} />
      <div className={styles.rightSectionLogo}>
        <Typography variant="h4" gutterBottom mt={3} mb={3} pl={0} sx={{ fontSize: "1.5rem" }}>
          <span className={styles.companyName}>EarningsStream</span>
        </Typography>
      </div>
      <Grid container className={styles.container}>
        <Grid item xs={12} md={6} className={styles.rightSection}>
          <Box className={styles.formBox}>
            <Typography gutterBottom sx={{ fontSize: "1.5rem", fontWeight: 600 }} mb={3}>
              Sign in to your account
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormField name="email" label="Email" />
                  <FormField name="password" label="Password" type="password" />
                  <PrimaryButton
                    id="sign_in_button"
                    label="Sign in"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
            <Typography variant="body2" align="center" className={styles.signInText} mt={4}>
              New to EarningsStream? <Link component={RouterLink} to="/register" className={styles.boldLink}>Create account</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

export default SignIn;