export type UserProps = {
	id: string;
	createdAt?: Date;
	updatedAt?: Date;
	name: string;
	email: string;
	password: string;
};

export default class BaseUser {
  id!: string;

  createdAt?: Date;

  updatedAt?: Date;

  name!: string;

  email!: string;

  password!: string;
	
	isAdmin!: boolean;
	
	isStaff!: boolean;
	
	constructor(props: UserProps) {
		Object.assign(this, props);
	}
	
	toString() {
		return this.name;
	}
}