import { ReportingWindow } from "../types";

export type EarningsEventProps<ConferenceEventType, UpcomingEarningsEventType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  conferenceEvent: ConferenceEventType;
  reportingWindow: ReportingWindow;
  upcomingEarningsEvent?: UpcomingEarningsEventType;
};

export default class BaseEarningsEvent<ConferenceEventType, UpcomingEarningsEventType> {
  id?: number;

  createdAt?: Date;

  updatedAt?: Date;

  conferenceEvent!: ConferenceEventType;

  reportingWindow!: ReportingWindow;

  upcomingEarningsEvent?: UpcomingEarningsEventType;

  constructor(props: EarningsEventProps<ConferenceEventType, UpcomingEarningsEventType>) {
    Object.assign(this, props);
  }

  toString() {
    return `EarningsEvent: ${this.conferenceEvent}`;
  }
}