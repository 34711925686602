export type PersonProps = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  name: string;
};

export default class BasePerson {
  id!: number;

  createdAt?: Date;

  updatedAt?: Date;

  name!: string;
  
  constructor(props: PersonProps) {
    Object.assign(this, props);
  }

  toString() {
    return `${this.name} (${this.id})`;
  }
}
