import { FC } from "react";
import { Alert, Snackbar } from "@mui/material";

type ErrorToastProps = {
	dismiss: () => void;
	message: string;
	pixelsFromTop?: number;
};

const ErrorToast: FC<ErrorToastProps> = ({ dismiss, message, pixelsFromTop = 74 }) => {
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dismiss();
  };
	
	return (
		<Snackbar
			open={!!message}
			autoHideDuration={6000}
			onClose={() => handleClose()}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			sx={{ top: `${pixelsFromTop}px !important` }}
		>
		<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
			{message}
		</Alert>
	</Snackbar>
	);
};

export default ErrorToast;