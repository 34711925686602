import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import LogoButton from "./LogoButton";
import NavDrawer from "./NavDrawer";
import ButtonWithPopover from "./ButtonWithPopover";

const NavBar: React.FC = () => {
  const { authenticatedUserProfile, logOutUser } = useUserContext();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogoutClick = () => {
    logOutUser();
    navigate("/");
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" sx={{ zIndex: 1000 }}>
      <Box sx={{ maxWidth: "1080px", width: "100%", mx: "auto" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <LogoButton />
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 2, flexGrow: 1 }}>
            {authenticatedUserProfile && (
              <>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/profile"
                  sx={{ textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
                >
                  Dashboard
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/upcoming-earnings-events"
                  sx={{ textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
                >
                  Earnings Calendar
                </Button>
              </>
            )}
            <Button
              color="inherit"
              component={RouterLink}
              to="https://earningsstream.gitbook.io/earnings-stream"
              target="_blank"
              sx={{ textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
            >
              Developer Guide
            </Button>
            {authenticatedUserProfile?.user.isAdmin && (
              <ButtonWithPopover label="Admin" links={[
                { label: "Demo", link: "/demo" },
                { label: "Sandbox Webcast", link: "/sandbox-webcast" }
              ]}/>
            )}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 2 }}>
            {authenticatedUserProfile ? (
              <Button
                color="inherit"
                onClick={handleLogoutClick}
                sx={{ textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
              >
                Log out
              </Button>
            ) : (
              <>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/register"
                  sx={{ textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
                >
                  Sign up
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#fff",
                    color: "#2b6653",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "bold",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center"
                  }}
                  component={RouterLink}
                  to="/login"
                >
                  Sign in <ChevronRightIcon />
                </Button>
              </>
            )}
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: "100%",
                height: "100%",
                backgroundColor: "#fff"
              }
            }}
          >
            <NavDrawer toggleDrawer={toggleDrawer} handleLogoutClick={handleLogoutClick} />
          </Drawer>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;