import { Country, URLString, URLStringOrNull } from "../types";

export type CompanyProps = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  ticker: string;
  name: string;
  country?: Country;
  companyWebsiteUrl?: URLStringOrNull;
  investorRelationsUrl?: URLStringOrNull;
  earningsCallEventsListUrl?: URLStringOrNull;
  isPublic?: boolean;
};

export default class BaseCompany {
  id!: number;

  createdAt?: Date;

  updatedAt?: Date;

  ticker!: string;

  name!: string;

  country?: Country = Country.UNITED_STATES;

  isPublic?: boolean = true;

  companyWebsiteUrl?: URLString | null = null;
  
  companyWebsiteUrlLastUpdatedAt?: Date | null = null;

  hasEarningsCalls?: boolean = false;

  hasPhoneOnlyCalls?: boolean = false;

  lastHasEarningsCallSearchAt?: Date | null = null;

  investorRelationsUrl?: URLString | null = null;
  
  investorRelationsUrlLastUpdatedAt?: Date | null = null;

  earningsCallEventsListUrl?: URLString | null = null;

  lastEarningsCallEventsListUrlSearchAt?: Date | null = null;

  isCompanyWebsiteCaptchaProtected?: boolean = false;

  executivesLastUpdatedAt?: Date | null = null;

  isDow30Component?: boolean = false;

  isSAndP500Component?: boolean = false;
  
  isWebcastLinkAvailableEarly?: boolean = true;
  
  constructor(props: CompanyProps) {
    Object.assign(this, props);
  }

  toString() {
    return `${this.ticker}: ${this.name}`;
  }

}