import { createContext, useContext } from "react";

import { UserProfile } from "../models";

interface UserContextType {
	
  authenticatedUserProfile: UserProfile | null;
  
  setAuthenticatedUserProfile: (user: UserProfile) => void;

  logInUser: (userProfile: UserProfile) => void;

  logOutUser: () => void;

};

const UserContext = createContext<UserContextType>(
  {
    authenticatedUserProfile: null,
    setAuthenticatedUserProfile: () => {},
    logInUser: () => {},
    logOutUser: () => {}
  }
);

export const useUserContext = () => useContext(UserContext);

export default UserContext;