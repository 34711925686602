import { useEffect, useRef } from "react";
import { Box } from "@mui/material";

const LiveStreamPlayer = ({ src }: { src: string }) => {
    if (!src) {
      return null;
    };
    
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = src;
            audioRef.current.play().catch(err => console.error('Error playing audio:', err));
        }
    }, []);

    return (
      <Box sx={{ marginTop: 2 }}>
        <audio ref={audioRef} controls>
            Your browser does not support the audio element.
        </audio>
      </Box>
    );
};

export default LiveStreamPlayer;