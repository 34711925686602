export const EARNINGS_CALL_SCHEDULED_ANNOUNCEMENT_LANGUAGE = [
  "after market close on",
  "announces date for earnings release",
  "announces release date",
  "before market open on",
  "earnings announcement",
  "earnings conference call",
  "earnings release",
  "earning release",
  "reporting date",
  "results announcement",
  "results on",
  "results to be released",
  "sets first",
  "sets second",
  "sets third",
  "sets fourth",
  "announces timing",
  "timing and availability",
];

export const RESULTS_ANNOUNCEMENT_METRICS_LANGUAGE = [
  "business update",
  "ebitda",
  "earnings",
  "net income",
  "revenue",
  "results",
  "first quarter",
  "first-quarter",
  "second quarter",
  "second-quarter",
  "third quarter",
  "third-quarter",
  "fourth quarter",
  "fourth-quarter",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
  "1Q",
  "2Q",
  "3Q",
  "4Q",
  "full year",
  "full-year",
  "fiscal year",
  "fiscal-year",
  "yearend",
  "year-end",
  "year end",
].map((metric) => metric.toLowerCase());

export const CALL_SCHEDULED_ANNOUNCEMENT_LANGUAGE = [
  "announcement to be made on",
  "announces date of",
  "announces investor call",
  "announces webcast",
  "call and webcast",
  "call scheduled",
  "call on",
  "call for",
  "call to discuss",
  "conference call",
  " date of",
  " date for",
  "holding of",
  "schedules",
  "timing of",
  " to announce",
  " to host",
  " to hold",
  " to provide",
  " to release",
  " to report",
  " to webcast",
  "will release",
  "webcast",
  "invitation to",
  "notice of",
];