import React from "react";
import { Routes, Route, Navigate, RouteProps } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";

interface ProtectedRoutesProps {
  children: React.ReactNode;
  unprotectedRoutes: string[];
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ children, unprotectedRoutes }) => {
  const { authenticatedUserProfile } = useUserContext();
  return (
    <Routes>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement<RouteProps>(child)) {
          return null;
        }

        const { path } = child.props;
				
        const isUnprotected = unprotectedRoutes.includes(path as string);
        
        if (isUnprotected || authenticatedUserProfile) {
          return child;
        }

        return <Route key={path as string} path={path} element={<Navigate to="/" />} />;
      })}
    </Routes>
  );
};

export default ProtectedRoutes;