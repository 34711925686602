import { ReactNode } from "react";
import { Box } from "@mui/material";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Box sx={{ maxWidth: "1080px", width: "100%", mx: "auto", mt: 5, p: 2 }}>
      {children}
    </Box>
  );
};


export default Layout;