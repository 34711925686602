import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import io, { Socket } from "socket.io-client";
import { Box, Button, CircularProgress, Container, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { wait } from "@bruinen/business_logic";
import { UserProfile } from "../../models";
import { LiveStreamPlayer } from "../../components/LiveStreamPlayer";
import { formatTimestamp, formatTime, formatDuration } from "../../utils";
import { Utterance, EventDetails } from "../../types";

const Demo = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [utterances, setUtterances] = useState<Utterance[]>([]);
  const [livestreamUrl, setLivestreamUrl] = useState<string>("");
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);
  const utteranceListRef = useRef<HTMLUListElement>(null);
  const { conferenceEventId } = useParams();

  useEffect(() => {
    if (eventDetails) {
      return;
    }
    const fetchEventDetails = async () => {
      setLoading(true);
      try {
        const response = await UserProfile.fetchConferenceEvent(conferenceEventId as string);
        setEventDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch event details", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventDetails, conferenceEventId]);

  useEffect(() => {
    if (utteranceListRef.current) {
      utteranceListRef.current.scrollTop = utteranceListRef.current.scrollHeight;
    }
  }, [utterances]);

  const handleNewUtterance = (item: Utterance) => {
    setUtterances((prevUtterances) => {
      if (!prevUtterances.find((utterance) => utterance.startTime === item.startTime)) {
        return [...prevUtterances, item];
      }
      return prevUtterances;
    });
  };

  const handleStartDemo = async () => {
    setLoading(true);
    setLivestreamUrl("");
    setUtterances([]);
    const newSocket = io(process.env.REACT_APP_SOCKET_URL as string);
    setSocket(newSocket);
    newSocket.on("isReady", async (url: string) => {
      // wait 2 seconds before setting the livestream URL
      await wait(2000);
      setLoading(false);
      // Directly set the livestream URL
      setLivestreamUrl(`${url}/${conferenceEventId}.mp3`);
    });
    newSocket.on("transcript-chunk", async (item: Utterance) => {
      handleNewUtterance(item);
    });
  };
  
  const handleStopDemo = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
    setLivestreamUrl("");
    setLoading(false);
  };

  if (!eventDetails || !eventDetails.category) {
    return null;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, position: "sticky", top: 0, zIndex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {eventDetails.companyName} ({eventDetails.ticker})
        </Typography>
        <Typography variant="subtitle1">
          {eventDetails.fiscalQuarter} {eventDetails.fiscalYear} {eventDetails.category.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Call starts at: {formatTime(eventDetails.callStartsAt)}
        </Typography>
        <LiveStreamPlayer src={livestreamUrl} />
        {!socket ? (
          <Button variant="contained" color="primary" onClick={handleStartDemo} sx={{ marginTop: 2 }}>
            Start Demo
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={handleStopDemo} sx={{ marginTop: 2 }}>
            Disconnect
          </Button>
        )}
      </Paper>

      <Box sx={{ marginTop: 4, border: "1px solid #ccc", borderRadius: "4px", padding: 2, maxHeight: "60vh", overflowY: "auto" }}>
        <Typography variant="h5" gutterBottom>
          Live Transcriptions
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Calls often start with non-verbal audio (like elevator music). We are waiting to detect speakers.
            </Typography>
          </Box>
        ) : (
          utterances.length === 0 ? (
            <Typography variant="body1">
              Audio segments will be transcribed and then displayed here.
            </Typography>
          ) : (
            <List ref={utteranceListRef}>
              {utterances.map((utterance, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={utterance.text}
                    secondary={`Start: ${formatTimestamp(utterance.startTime)}, End: ${formatTimestamp(utterance.endTime)}, Duration: ${formatDuration(utterance.duration)}`}
                  />
                </ListItem>
              ))}
            </List>
          )
        )}
      </Box>
    </Container>
  );
};

export default Demo;