import { ReactNode } from "react";
import { Box } from "@mui/material";
import styles from './ResponsiveContainer.module.scss';

const ResponsiveContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box className={styles.responsiveContainer}>
      <Box className={styles.contents}>
        {children}
      </Box>
    </Box>
  );
};


export default ResponsiveContainer;