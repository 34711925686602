import { Box, Typography, TextField } from "@mui/material";
import { useField } from "formik";
import { WarningAmberRounded } from "@mui/icons-material";
import styles from "./FormField.module.scss";

interface FormFieldProps {
  name: string;
  label?: string;
  type?: string;
}

const FormField: React.FC<FormFieldProps> = ({ name, label = "", type = "text" }) => {
  const [field, meta] = useField(name);

  return (
    <Box className={styles.formField}>
      {label && (
        <Typography variant="body2" className={styles.inputLabel} mb={1}>
          {label}
        </Typography>
      )}
      <TextField
        fullWidth
        variant="outlined"
        {...field}
        id={name}
        type={type}
        InputProps={{
          className: styles.input,
        }}
        error={meta.touched && Boolean(meta.error)}
        FormHelperTextProps={{ className: styles.errorText }}
        helperText={meta.touched && meta.error ? (
          <Box display="flex" alignItems="center" sx={{ marginLeft: "-14px" }}>
            <WarningAmberRounded color="error" sx={{ marginRight: "16px", fontSize: "16px" }} />
            <span style={{ marginLeft: "-14px", fontSize: "12px", marginBottom: "-3px" }}>
              {meta.error}
            </span>
          </Box>
        ) : ""}
      />
    </Box>
  );
};

export default FormField;
