import { useEffect, useRef, useState } from "react";
import { Alert, Button, CircularProgress, Container, Typography, Link, Box, Grid, Paper } from '@mui/material';
import { UserProfile } from "../../models";

type EventDetails = {
  eventName: string;
  fiscalQuarter: string;
  fiscalYear: number;
  callStartsAt: string;
  webcastUrl: string;
  fileCount: number;
};

// we'll make this more dynamic later
const conferenceEventId = 1

const SandboxWebcast = () => {
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);
  const [hasEventStarted, setHasEventStarted] = useState<boolean>(false);
  const [isEventCompleted, setIsEventCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const currentFileOrder = useRef<number>(0);
  
  const incrementCurrentFileOrder = () => {
    currentFileOrder.current += 1;
  }
  
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setLoading(false);
      setCountdown(0);
    }
    return () => clearTimeout(timer);
  }, [countdown]);
  
  const handleStartWebcast = async () => {
    setLoading(true);
    setCountdown(3);
    setHasEventStarted(true);
  };

  useEffect(() => {
    if (eventDetails) {
      return;
    }
    const fetchEventDetails = async () => {
      setLoading(true);
      try {
        const response = await UserProfile.fetchConferenceEvent(conferenceEventId.toString());
        setEventDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch event details", error);
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch of event details
    fetchEventDetails();
  }, [eventDetails]);

  useEffect(() => {
    if (!eventDetails || eventDetails.fileCount === 0 || currentFileOrder.current > eventDetails.fileCount || !hasEventStarted || loading) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return;
    }

    const fetchFiles = async () => {
      if (currentFileOrder.current > eventDetails.fileCount) {
        return;
      }
      try {
        const startTime = Date.now();
        const response = await UserProfile.fetchConferenceEventLiveStreamFile(conferenceEventId.toString(), `${currentFileOrder.current.toString()}.ts`);
        const endTime = Date.now();
        const requestDuration = (endTime - startTime);
        const fileDuration = parseFloat(response.headers?.["x-file-duration"]) * 1000;
        // wait less time b/t the first and second file
        const duration = fileDuration - requestDuration;
        incrementCurrentFileOrder();
        if (!!eventDetails?.fileCount && currentFileOrder.current > eventDetails?.fileCount) {
          setIsEventCompleted(true);
        }
        // Clear and set a new interval based on the duration
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(fetchFiles, duration);
      } catch (error) {
        console.error("Failed to fetch files", error);
      }
    };
    
    fetchFiles();

    // Cleanup interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [eventDetails, hasEventStarted, loading]);

  
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {isEventCompleted && (
        <Alert severity="success" variant="filled" sx={{ mb: 2 }}>
          Event has completed. You are now free to disconnect.
        </Alert>
      )}
      <Paper elevation={3} sx={{ p: 3 }}>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        )}
        {!loading && eventDetails?.eventName && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Event Details:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Event Name:</strong> {eventDetails.eventName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Fiscal Quarter:</strong> {eventDetails.fiscalQuarter}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Fiscal Year:</strong> {eventDetails.fiscalYear}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Call Starts At:</strong> {eventDetails.callStartsAt}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Webcast URL: </strong> 
                  <Link href={eventDetails.webcastUrl} target="_blank" rel="noreferrer">
                    {eventDetails.webcastUrl}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            {!hasEventStarted && (
              <Grid container justifyContent="center">
                <Grid item>
                  <Box mt={4} display="flex" justifyContent="center">
                    <Button id="start_webcast" variant="contained" onClick={handleStartWebcast}>Start Webcast</Button>
                  </Box>
                </Grid>
              </Grid>
            )}
            {loading && (
              <>
                <CircularProgress style={{ marginTop: '16px' }} />
                  <Typography variant="h6" style={{ marginTop: '16px' }}>
                    Event is starting in {countdown}
                  </Typography>
              </>
            )}
            {!loading && hasEventStarted && (
              <Typography variant="h6" style={{ marginTop: '16px' }}>
                Event is in progress. Inspect the network tab for incoming audio files.
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default SandboxWebcast;