import { Navigate } from "react-router-dom";

import CreateAccount from "./CreateAccount";
import Demo from "./Demo";
import Home from "./Home/Home";
import LiveStream from "./LiveStream";
import Profile from "./Profile";
import SandboxWebcast from "./SandboxWebcast";
import SignIn from "./SignIn";
import UpcomingEarningsEvents from "./UpcomingEarningsEvents";

const Pages = [
  {
    path: "/register",
    element: <CreateAccount />,
    isProtected: false,
    useLayout: false,
  },
  {
    path: "/login",
    element: <SignIn />,
    isProtected: false,
    useLayout: false,
  },
  {
    path: "/profile",
    element: <Profile />,
    isProtected: true,
    useLayout: true,
  },
  {
    path: "/",
    element: <Home />,
    isProtected: false,
    useLayout: false,
  },
  {
    path: "/demo",
    element: <Navigate to="/demo/events/1/livestream" />,
    isProtected: true,
    useLayout: true,
  },
  {
    path: "/demo/events/:conferenceEventId/livestream",
    element: <Demo />,
    isProtected: true,
    useLayout: true,
  },
  {
    path: "/upcoming-earnings-events",
    element: <UpcomingEarningsEvents />,
    isProtected: true,
    useLayout: true,
  },
  {
    path: "/events/:conferenceEventId/livestream",
    element: <LiveStream />,
    isProtected: true,
    useLayout: true,
  },
  {
    path: "/sandbox-webcast",
    element: <SandboxWebcast />,
    isProtected: true,
    useLayout: true,
  }
]

export default Pages;