import { Button } from "@mui/material";
import styles from "./PrimaryButton.module.scss";

type PrimaryButtonProps = {
	id?: string;
	label: string;
	type?: "button" | "submit" | "reset" | undefined;
	disabled?: boolean;
};

const PrimaryButton = ({ id, label, type = "button", disabled = false }: PrimaryButtonProps) => (
	<Button
	  id={id || label}
		type={type}
		disabled={disabled}
		fullWidth
		variant="contained"
		color="primary"
		className={styles.primaryButton}
		sx={{ textTransform: "none", fontSize: "16px" }}
	>
		{label}
	</Button>
);

export default PrimaryButton;