export type EventTranscriptProps<ConferenceEventType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  conferenceEvent: ConferenceEventType;
};

export default class BaseEventTranscript<ConferenceEventType> {
	id?: number;
	createdAt?: Date;
	updatedAt?: Date;
	conferenceEvent!: ConferenceEventType;
	
	constructor(props: EventTranscriptProps<ConferenceEventType>) {
		Object.assign(this, props);
	}
	
  toString() {
    return `EventTranscript: ${this.conferenceEvent} (${this.id})`;
  }
}