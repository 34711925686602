import { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import Theme from "./theme";
import Pages from "./pages/Pages";
import { User, UserProfile } from "./models";
import UserContext from "./contexts/UserContext";
import ProtectedRoutes from "./components/ProtectedRoutes";
import NavBar from "./components/NavBar/NavBar";
import Layout from "./components/Layout"
import Footer from "./components/Footer";
import ErrorToast from "./components/ErrorToast";

function App() {  
  const location = useLocation();
  const [authenticatedUserProfile, setAuthenticatedUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");

  const logInUser = (userProfile: UserProfile) => {
    setAuthenticatedUserProfile(userProfile);
  };
  
  const logOutUser = async () => {
    localStorage.removeItem("earningsStreamToken");
    setAuthenticatedUserProfile(null);
  };
  
  // List of routes where the Navbar should not be displayed
  const loginPages = ["/register", "/login"];
  const unAuthenticatedRoutes = ["/", "/sandbox-webcast", "/developer-guide", ...loginPages];
  const isUserOnUnauthenticatedPage = unAuthenticatedRoutes.includes(location.pathname);
  const isUserOnLoginPage = loginPages.includes(location.pathname);

  useEffect(() => {
    const fetchMe = async () => {
      setError("");
      const { userProfile, error } = await UserProfile.fetchMe();
      setLoading(false);
      if ((error || !userProfile)) {
        setAuthenticatedUserProfile(null);
        if (!isUserOnUnauthenticatedPage) {
          setError("Failed to fetch your profile. Please refresh the page.");
        }
        return
      }
      setAuthenticatedUserProfile(userProfile);
    };
    fetchMe();
  }, [isUserOnUnauthenticatedPage]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={Theme}>
      <UserContext.Provider value={{ authenticatedUserProfile, setAuthenticatedUserProfile, logInUser, logOutUser }}>
        {!isUserOnLoginPage && <NavBar />}
        <ErrorToast dismiss={() => setError("")} message={error} />
        <ProtectedRoutes unprotectedRoutes={Pages.filter(({ isProtected }) => !isProtected).map(({ path }) => path)}>
          {Pages.map(({ path, element, useLayout }) => (
            <Route key={path} path={path} element={useLayout ? (<Layout>{element}</Layout>) : element} />
          ))}
        </ProtectedRoutes>
        <Footer />
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;