import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#2b6653', // Dark green
    },
    secondary: {
      main: '#b7a99a', // Darker earth tone
    },
    error: {
      main: "rgb(205, 61, 100)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#2b6653', // Dark green on hover
            color: '#fff', // White text on hover
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#2b6653', // Dark green on hover
            color: '#fff', // White text on hover
          },
        },
      },
    },
  },
});

export default Theme;