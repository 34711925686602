import React, { useEffect, useState } from "react";
import {
  Container, Typography, Box, Link, CircularProgress, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Button
} from "@mui/material";
import { DateTime } from "luxon";
import FetchApi from "../../services/FetchApi";
import ErrorToast from "../../components/ErrorToast";
import { UpcomingEarningsEvent } from "../../models";
import { ReportingWindow } from "@bruinen/business_logic";

type FetchedUpcomingEarningsEvent = UpcomingEarningsEvent & {
  earningsEvent: { conferenceEventId: number }
  callStartsAt?: string;
  id: number;
};

interface GroupedEvents {
  [date: string]: {
    BEFORE_OPEN: FetchedUpcomingEarningsEvent[];
    DURING_MARKET_HOURS: FetchedUpcomingEarningsEvent[];
    AFTER_HOURS: FetchedUpcomingEarningsEvent[];
  };
}

const getTime = (utcDateString: string): string => {
  const easternTime = DateTime.fromISO(utcDateString, { zone: "utc" })
    .setZone("America/New_York");

  let hour = easternTime.hour % 12 || 12; // convert to 12-hour format, where 0 is 12
  const minute = easternTime.minute.toString().padStart(2, "0"); // zero-pad minute

  const amPm = easternTime.hour < 12 ? "AM" : "PM";

  return `${hour}:${minute} ${amPm} ET`;
};

const groupEventsByDateAndWindow = (events: FetchedUpcomingEarningsEvent[]): GroupedEvents => {
  const groupedEvents: GroupedEvents = {};

  events.forEach((event) => {
    const date = new Date(event.date as unknown as string).toISOString().split("T")[0]; // TODO fix this

    if (!groupedEvents[date]) {
      groupedEvents[date] = {
        BEFORE_OPEN: [],
        DURING_MARKET_HOURS: [],
        AFTER_HOURS: [],
      };
    }

    groupedEvents[date][event.reportingWindow].push(event);
  });

  return groupedEvents;
};

const UpcomingEarningsEvents: React.FC = () => {
  const [groupedEvents, setGroupedEvents] = useState<GroupedEvents>({});
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      try {
        const { data: earningsEvents, error } = await FetchApi.get<FetchedUpcomingEarningsEvent[]>({ expectedResponse: 200, endpoint: "upcoming-events" });
        if (error || !earningsEvents) {
          setError(error);
          return;
        }
        const events = groupEventsByDateAndWindow(earningsEvents);
        setGroupedEvents(events);
        setSelectedDate(Object.keys(events)[0]); // Set the first date as the default selected date
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchUpcomingEvents();
  }, []);

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  const handleDateChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedDate(newValue);
  };

  const goToCall = (eventId: number) => {
    window.open(`/events/${eventId}/livestream`, "_blank");
  };
  
  const isLive = (callStartsAt: string) => {
    if (!callStartsAt) {
      return false;
    }
    const callStartTimeEastern = DateTime.fromISO(callStartsAt, { zone: "utc" }).setZone("America/New_York");
    const nowEastern = DateTime.now().setZone("America/New_York");
    // Define the acceptable time window: from 1 hour ago to 5 minutes from now
    const oneHourAgo = nowEastern.minus({ hours: 1 });
    const fiveMinutesFromNow = nowEastern.plus({ minutes: 5 });
    return callStartTimeEastern >= oneHourAgo && callStartTimeEastern <= fiveMinutesFromNow;
  };

  const canListenLive = (eventId?: number, callStartsAt?: string) => {
    if (!eventId || !callStartsAt) {
      return false;
    }
    return isLive(callStartsAt);
  };
  
  return (
    <Container>
      <ErrorToast dismiss={() => setError("")} message={error} />
      <Box sx={{ mt: 6, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Upcoming Earnings Events
        </Typography>
      </Box>
      <Tabs
        value={selectedDate}
        onChange={handleDateChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="date tabs"
        sx={{ mb: 3 }}
      >
        {Object.keys(groupedEvents).map((date) => (
          <Tab key={date} label={DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)} value={date} />
        ))}
      </Tabs>
      {selectedDate && (
        <Box sx={{ mt: 4 }}>
          {(["BEFORE_OPEN", "DURING_MARKET_HOURS", "AFTER_HOURS"] as ReportingWindow[]).map((window) => (
            groupedEvents[selectedDate][window].length > 0 && (
              <Box key={window} sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                  {window === "BEFORE_OPEN"
                    ? "Before Market Open"
                    : window === "DURING_MARKET_HOURS"
                    ? "During Market Hours"
                    : "After Market Close"}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Is Webcast Link Available Early?</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Webcast URL</TableCell>
                        <TableCell>Corporate Website</TableCell>
                        <TableCell>Investor Relations Website</TableCell>
                        <TableCell>Events List URL</TableCell>
                        <TableCell>Press Release</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedEvents[selectedDate][window].map((event) => (
                        (
                          <TableRow key={event.id}>
                            {canListenLive(event.earningsEvent?.conferenceEventId, event.callStartsAt) ? (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => goToCall(event.earningsEvent.conferenceEventId)}
                                >
                                  Listen Live
                                </Button>
                              </TableCell>
                            ) : (<TableCell></TableCell>)}
                            <TableCell>
                              <Typography variant="body1">{event.company.ticker}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{event.company.name}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{event.company.isWebcastLinkAvailableEarly ? "Y" : "N"}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{event.callStartsAt ? getTime(event.callStartsAt) : ""}</Typography>
                            </TableCell>
                            <TableCell>
                              <Link component="a" href={event.upcomingWebcastUrl || ""} target="_blank" rel="noopener">
                                {event.upcomingWebcastUrl}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link component="a" href={event.company.companyWebsiteUrl || ""} target="_blank" rel="noopener">
                                {event.company.companyWebsiteUrl}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link component="a" href={event.company.investorRelationsUrl || ""} target="_blank" rel="noopener">
                                {event.company.investorRelationsUrl}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link component="a" href={event.company.earningsCallEventsListUrl || ""} target="_blank" rel="noopener">
                                {event.company.earningsCallEventsListUrl}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link component="a" href={event.pressRelease?.url || ""} target="_blank" rel="noopener">
                                {event.pressRelease?.title}
                              </Link>
                            </TableCell>
                          </TableRow>
                        )
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          ))}
        </Box>
      )}
    </Container>
  );
};

export default UpcomingEarningsEvents;