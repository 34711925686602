import axios, { AxiosResponse } from "axios";

const ENDPOINTS_MAP = {
  register: "auth/register",
  login: "auth/login",
  logout: "auth/logout",
  "upcoming-events": "upcoming-events",
  me: "me",
  webhookConfigs: "webhook-configs",
  conferenceEvents: "conference-events/:conferenceEventId",
  conferenveEventLiveStreamFiles: "conference-events/:conferenceEventId/livestream-files/:fileOrder",
  demo: "admin/demo",
};

type RequestProps = {
  expectedResponse: number;
  endpoint: keyof typeof ENDPOINTS_MAP;
  withCredentials?: boolean;
  params?: Record<string, string | number>;
};

type PostProps = RequestProps & {
  payload: Record<string, unknown>;
};

export type ErrorResponse = {
  error: string;
  data: null;
  headers?: null;
};

export type SuccessResponse<T> = {
  error: null;
  data: T;
  headers?: AxiosResponse["headers"];
};

export type Response<T> = ErrorResponse | SuccessResponse<T>;

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor to include the token in the headers
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("earningsStreamToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default class FetchApi {
  static buildUrl(endpoint: keyof typeof ENDPOINTS_MAP, params?: Record<string, string | number>): string {
    if (!ENDPOINTS_MAP[endpoint]) {
      throw new Error(`Endpoint ${endpoint} not found`);
    }

    let url = ENDPOINTS_MAP[endpoint];

    if (params) {
      Object.keys(params).forEach(key => {
        url = url.replace(`:${key}`, String(params[key]));
      });
    }

    return `${process.env.REACT_APP_API_URL}/${url}`;
  }

  static buildOptions(withCredentials: boolean): Record<string, boolean> {
    return withCredentials ? { withCredentials: true } : {};
  }

  static async get<T>({
    expectedResponse,
    endpoint,
    withCredentials = true,
    params,
  }: RequestProps): Promise<Response<T>> {
    const url = FetchApi.buildUrl(endpoint, params);
    const options = FetchApi.buildOptions(withCredentials);
    try {
      const response = await axiosInstance.get(url, options);
      if (response.status !== expectedResponse) {
        return { data: null, error: response.data.error as string, headers: null };
      }
      return { data: response.data, error: null, headers: response.headers };
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return { data: null, error: error.response.data.error as string };
      }
      return { data: null, error: "An unexpected error occurred." };
    }
  }
  
  static async put<T>({
    expectedResponse,
    endpoint,
    payload,
    withCredentials = true,
    params,
  }: PostProps): Promise<Response<T>> {
    const url = FetchApi.buildUrl(endpoint, params);
    const options = FetchApi.buildOptions(withCredentials);
    try {
      const response = await axiosInstance.put(url, payload, options);
      if (response.status !== expectedResponse) {
        return { data: null, error: response.data.error as string };
      }
      return { data: response.data as T, error: null };
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return { data: null, error: error.response.data.error as string };
      }
      return { data: null, error: "An unexpected error occurred." };
    }
  }

  static async post<T>({
    expectedResponse,
    endpoint,
    payload,
    withCredentials = true,
    params,
  }: PostProps): Promise<Response<T>> {
    const url = FetchApi.buildUrl(endpoint, params);
    const options = FetchApi.buildOptions(withCredentials);
    try {
      const response = await axiosInstance.post(url, payload, options);
      if (response.status !== expectedResponse) {
        return { data: null, error: response.data.error as string };
      }
      return { data: response.data as T, error: null };
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return { data: null, error: error.response.data.error as string };
      }
      return { data: null, error: "An unexpected error occurred." };
    }
  }
}