import { URLString } from "../types";

export type RealTimeTranscriptionChunkProps<ConferenceEventType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  order: number;
  fileId: string;
  fileUri: string;
  startsAt?: Date;
  endsAt: Date;
  conferenceEvent: ConferenceEventType;
};

export default class BaseRealTimeTranscriptionChunk<ConferenceEventType> {
  id!: number;

  createdAt?: Date;

  updatedAt?: Date;

  order!: number; // This is the order of the chunk relative to all chunks in the call

  fileId!: string;

  fileUri!: URLString;

  startsAt?: Date;

  endsAt!: Date;

  conferenceEvent!: ConferenceEventType;

  toString() {
    return `RealTimeTranscriptionChunk: ${this.fileUri} @ ${this.endsAt}`;
  }

  constructor(props: RealTimeTranscriptionChunkProps<ConferenceEventType>) {
    const id = props.id || -1;
    Object.assign(this, { ...props, id });
  }
}