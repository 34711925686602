import { ReportingWindow, URLStringOrNull } from "../types";

export type UpcomingEarningsEventProps<CompanyType, PressReleaseType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  company: CompanyType;
  date: Date;
  pressRelease?: PressReleaseType;
  earningsEventFailedFetchCount?: number;
  isConfirmed?: boolean;
  reportingWindow: ReportingWindow;
};

export default class BaseUpcomingEarningsEvent<CompanyType, PressReleaseType> {
  id?: number;

  createdAt?: Date;

  updatedAt?: Date;

  company!: CompanyType;

  date!: Date;

  pressRelease?: PressReleaseType;

  earningsEventFailedFetchCount: number = 0;

  upcomingWebcastUrl?: URLStringOrNull = null;

  earningsEventDetailsUrl?: URLStringOrNull = null;

  lastEarningsEventDetailsUrlSearchAt?: Date;

  lastUpcomingWebcastUrlSearchAt?: Date;

  lastPressReleaseSearchAt?: Date;

  isConfirmed?: boolean = false;

  reportingWindow: ReportingWindow = ReportingWindow.BEFORE_OPEN;
  
  earningsEventId?: number;
  
  constructor(props: UpcomingEarningsEventProps<CompanyType, PressReleaseType>) {
    Object.assign(this, props);
  }

  toString() {
    return `${this.company} on ${this.date}`;
  }
}
