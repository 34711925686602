import { DateTime } from "luxon";

export const formatTimestamp = (timestamp: number): string => {
  return DateTime.fromMillis(timestamp).setZone("America/New_York").toFormat("hh:mm:ss.SSS a");
};

export const formatTime = (timeString: string): string => {
  const date = new Date(timeString);
  return date.toLocaleString("en-US", { timeZone: "America/New_York" });
};

export const formatDuration = (duration: number): string => {
  const seconds = duration / 1000;
  return `${seconds.toFixed(3)} seconds`;
};