import { URLString } from "../types";

export const PressReleaseCategory = {
  EARNINGS_ANNOUNCEMENT: "EARNINGS_ANNOUNCEMENT",
} as const;

export type PressReleaseCategory =
  (typeof PressReleaseCategory)[keyof typeof PressReleaseCategory];

export type PressReleaseProps<CompanyType> = {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  company: CompanyType;
  category: PressReleaseCategory;
  releasedAt: Date;
  title: string;
  text: string;
  url: URLString;
  urls: URLString[];
};

export default class BasePressRelease<CompanyType> {
  id: number = -1;

  createdAt?: Date;

  updatedAt?: Date;

  company!: CompanyType;

  category!: PressReleaseCategory;

  releasedAt!: Date;

  title!: string;

  text!: string;

  url!: URLString;

  urls!: URLString[];

  constructor(props: PressReleaseProps<CompanyType>) {
    Object.assign(this, props);
  }

  toString() {
    return this.title;
  }
}