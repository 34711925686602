import { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Popover, Typography } from "@mui/material";

type Links = {
  label: string;
  link: string;
}[];

const ButtonWithPopover = ({ label, links }: { label: string; links: Links }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (buttonRef.current && popoverRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const { clientX: x, clientY: y } = event;

      const isInButton =
        x >= buttonRect.left && x <= buttonRect.right && y >= buttonRect.top && y <= buttonRect.bottom;
      const isInPopover =
        x >= popoverRect.left && x <= popoverRect.right && y >= popoverRect.top && y <= popoverRect.bottom;

      if (!isInButton && !isInPopover) {
        setAnchorEl(null);
      }
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [anchorEl]);

  return (
    <>
      <Button
        ref={buttonRef}
        color="inherit"
        onMouseEnter={handlePopoverOpen}
        sx={{ cursor: "pointer", textTransform: "none", fontSize: "15px", fontWeight: "bold", color: "#fff" }}
      >
        {label}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
						ref: popoverRef,
            style: { padding: "16px" },
          },
        }}      >
        <Box>
          {links.map(({ label, link }) => (
            <Typography key={label} variant="body1">
              <Button component={RouterLink} to={link} sx={{ textTransform: "none", fontSize: "14px", color: "#000", justifyContent: "start" }}>
                {label}
              </Button>
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default ButtonWithPopover;