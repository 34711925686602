import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Typography, Box, Grid } from "@mui/material";
import ReactPlayer from "react-player";
import videoFile from "../../static/videos/demo.mp4"; // Update the path as necessary
import styles from "./Home.module.scss"; // Import the SCSS module

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/register");
  };

  return (
    <>
    <div className={styles.movingGradient}></div>
    <Box sx={{ position: "relative", overflow: "hidden", maxWidth: "1080px", width: "100%", mx: "auto" }}>
      <Container maxWidth="lg" sx={{ py: 8, position: 'relative', zIndex: 2 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "44px", md: "70px" },
                }}
              >
                Earnings calls transcribed in real time
              </Typography>
              <Typography variant="h6" component="p" gutterBottom>
                A clean API to access instantaneous earnings call transcripts for all publically-traded U.S. equities.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleLoginClick}
                sx={{ textTransform: "none", borderRadius: "50px", mt: 3 }}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start", mt: -8 }}>
              <ReactPlayer
                url={videoFile}
                width="100%"
                height="100%"
                playing
                loop
                muted
                style={{ maxWidth: "500px", maxHeight: "300px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
  );
};

export default Home;